.div-todo-item {
  flex: 1;
  user-select: none;
  margin: 15px;
  padding: 1em 2em;
  background: #efefef;
  border: none;
  border-radius: 1.5rem;
  color: #444;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-align: start;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
}

.div-todo-item:hover {
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.div-todo-item:active {
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.line {
  text-decoration-color: rgb(255, 0, 0) !important;
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

.row-todo-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
  gap: 1rem;
}

.circle-cover {
  height: 20px;
  width: 20px;
}

.circle {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.high {
  background-color: #ff2a2a;
}

.medium {
  background-color: #ff9c2a;
}

.low {
  background-color: #ffea2a;
}
