.div-todo-edit-input {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  margin: 25px;
  padding: 1em 2em;
  background: #efefef;
  border: none;
  border-radius: 1.5rem;
  color: #444;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-align: start;
  outline: none;
  transition: 0.2s ease-in-out;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
}

.div-todo-edit-input:hover {
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.row-todo-edit-input {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.todo-edit-input {
  width: 100%;
  background-color: transparent;
  font-size: 1.5rem;
  border-style: none;
}

.todo-edit-input:focus {
  outline: none;
}

.button-hint {
  white-space: pre;
  user-select: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: #444;
}

.todo-edit-page-btn-div {
  margin: 25px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.todo-edit-page-btn {
  width: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  gap: 2rem;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  padding: 1.5em 10em;
  background: #efefef;
  border: none;
  border-radius: 50px;
  color: #444;
  font-size: 0.5rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-align: start;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
}

.todo-edit-page-btn:hover {
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.todo-edit-page-btn:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}

.todo-edit-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
