.edit-btn-div {
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-btn {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  padding: 1.5em;
  background: #efefef;
  border: none;
  border-radius: 50%;
  color: #444;
  font-size: 0.5rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-align: start;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 
    6px 6px 10px rgba(0, 0, 0, 0.15);
}

.edit-btn:hover {
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-btn:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}

.edit-btn-selected {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  padding: 1.5em;
  background: #efefef;
  border: none;
  border-radius: 50%;
  color: #444;
  font-size: 0.5rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-align: start;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}
