.div-todo-input {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  margin: 25px;
  padding: 1em 2em;
  background: #efefef;
  border: none;
  border-radius: 1.5rem;
  color: #444;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-align: start;
  outline: none;
  transition: 0.2s ease-in-out;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
}

.div-todo-input:hover {
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.row-todo-input {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.input-todo-input {
  width: 100%;
  background-color: transparent;
  font-size: 1.5rem;
  border-style: none;
}

.input-todo-input:focus {
  outline: none;
}

.todo-input-hint {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #444;
}

